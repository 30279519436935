
import { defineComponent, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
// import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTUserMenu from "@/layout/header/partials/ActivityDrawer.vue";
import KTLoader from "@/components/Loader.vue";
import KTCreateApp from "@/components/modals/wizards/CreateAppModal.vue";
// import KTExplore from "@/layout/extras/Explore.vue";
import KTDrawerMessenger from "@/layout/extras/DrawerMessenger.vue";
import { Actions } from "@/store/enums/StoreEnums";
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
} from "@/assets/ts/components/index";
import { removeModalBackdrop } from "@/core/helpers/dom";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";

import { ref } from 'vue'
import type { TabPaneName } from 'element-plus'
import { useI18n } from "vue-i18n"

export default defineComponent({
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    // KTFooter,
    KTToolbar,
    KTScrollTop,
    KTCreateApp,
    KTUserMenu,
    // KTExplore,
    KTDrawerMessenger,
    KTLoader,
  },
  setup() {
    const { t, te } = useI18n()
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      console.log(store.getters.pageBreadcrumbPath)
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      // console.log(store.getters.currentUser)

      //check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        router.push({ name: "sign-in" });
      }

      DrawerComponent.bootstrap();
      ScrollComponent.bootstrap();
      DrawerComponent.updateAll();
      ScrollComponent.updateAll();

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    // watch(
    //   () => route.path,
    //   () => {
    //     MenuComponent.hideDropdowns(undefined);

    //     DrawerComponent.hideAll();

    //     // check if current user is authenticated
    //     if (!store.getters.isUserAuthenticated) {
    //       router.push({ name: "sign-in" });
    //     }

    //     removeModalBackdrop();
    //   }
    // );

    let tabIndex = 2
    const editableTabsValue = ref('2')
    const editableTabs = ref([
      // {
      //   title: '商品管理',
      //   name: 'products',
      //   content: '',
      // },
      // {
      //   title: '套系管理',
      //   name: 'box_infos',
      //   content: '',
      // },
    ] as any)

    const handleTabsEdit = (
      targetName: TabPaneName | undefined,
      action: 'remove' | 'add'
    ) => {
      if (action === 'add') {
        const newTabName = `${++tabIndex}`
        // editableTabs.value.push({
        //   title: 'New Tab',
        //   name: newTabName,
        //   content: 'New Tab content',
        // })
        // editableTabsValue.value = newTabName
      } else if (action === 'remove') {
        const tabs = editableTabs.value

        if (tabs.length == 1) {
          return
        }

        let activeName = editableTabsValue.value

        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              const nextTab = tabs[index + 1] || tabs[index - 1]
              if (nextTab) {
                activeName = nextTab.name
              }
            }
          })
        }

        editableTabsValue.value = activeName
        editableTabs.value = tabs.filter((tab) => tab.name !== targetName)
      }
    }

    const handleTabsClick = (event: any) => {
      // console.log(event.props)
      // let path = editableTabs.value.filter((i) => {
      //   return i['name'] == event.props.name
      // })[0]['path']
      // router.push({path: path})
    }

    // watch(
    //   () => route.name,
    //   (to) => {
    //     if (editableTabs.value.filter((i) => {
    //       // return i['name'] == (route.name as string + route.params[Object.keys(route.params)[0]])
    //       return i['name'] == (route.name as string)
    //     }).length == 0) {
    //       editableTabs.value.push({
    //         // title: t(route.name as string, [route.params[Object.keys(route.params)[0]]]),
    //         title: t(route.name as string),
    //         // name: route.name as string + route.params[Object.keys(route.params)[0]],
    //         name: route.name as string,
    //         content: '',
    //         path: route.path,
    //       })
    //     }

    //     // editableTabsValue.value = route.name as string + route.params[Object.keys(route.params)[0]]
    //     editableTabsValue.value = route.name as string
    //   }
    // )

    // watch(
    //   () => editableTabsValue.value,
    //   () => {
    //     router.push({path: editableTabs.value.filter((i) => i['name'] == editableTabsValue.value)[0]['path']})
    //   }
    // )

    if (editableTabs.value.filter((i) => {
      return i['name'] == route.name
    }).length == 0) {
      editableTabs.value.push({
        // title: t(route.name as string, [route.params[Object.keys(route.params)[0]]]),
        // name: route.name as string + route.params[Object.keys(route.params)[0]],
        title: t(route.name as string),
        name: route.name as string,
        content: '',
        path: route.path,
      })
    }

    // editableTabsValue.value = route.name as string + route.params[Object.keys(route.params)[0]]
    editableTabsValue.value = route.name as string

    const cachedComponents = 'ProductIndex'

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      handleTabsEdit,
      editableTabsValue,
      editableTabs,
      handleTabsClick,
      cachedComponents
    };
  },
});
